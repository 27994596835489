<template>
    <div class="company-group-help">
        <CompanyGroupHelp />
    </div>
</template>

<script>
import CompanyGroupHelp from '@/components/settings/companyGroupHelp/index.vue';

export default {
    name: 'SettingsCompanyGroupHelp',
    components: {
        CompanyGroupHelp,
    },
};
</script>
