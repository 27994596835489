function icons() {
  return [
        'mdi-information',
        'mdi-information-outline',
        'mdi-information-variant',
        'mdi-help',
        'mdi-help-box',
        'mdi-help-circle',
        'mdi-help-circle-outline',
        'mdi-help-rhombus',
        'mdi-help-rhombus-outline',
        'mdi-crosshairs-question',
        'mdi-lifebuoy',
        'mdi-access-point',
        'mdi-access-point-network',
        'mdi-access-point-network-off',
        'mdi-account',
        'mdi-account-alert',
        'mdi-account-arrow-left',
        'mdi-account-arrow-right',
        'mdi-account-box',
        'mdi-account-box-multiple',
        'mdi-account-cancel',
        'mdi-account-cash',
        'mdi-account-check',
        'mdi-account-child',
        'mdi-account-circle',
        'mdi-account-clock',
        'mdi-account-cog',
        'mdi-account-convert',
        'mdi-account-edit',
        'mdi-account-badge',
        'mdi-account-badge-horizontal',
        'mdi-account-group',
        'mdi-account-key',
        'mdi-account-lock',
        'mdi-account-multiple',
        'mdi-account-search',
        'mdi-account-star',
        'mdi-account-star-outline',
        'mdi-account-supervisor-circle',
        'mdi-account-supervisor-outline',
        'mdi-account-tie',
        'mdi-account-outline',
        'mdi-account-alert-outline',
        'mdi-account-arrow-left-outline',
        'mdi-account-arrow-right-outline',
        'mdi-account-box-outline',
        'mdi-account-box-multiple-outline',
        'mdi-account-cancel-outline',
        'mdi-account-cash-outline',
        'mdi-account-check-outline',
        'mdi-account-child-outline',
        'mdi-account-circle-outline',
        'mdi-account-clock-outline',
        'mdi-account-cog-outline',
        'mdi-account-convert-outline',
        'mdi-account-edit-outline',
        'mdi-alert',
        'mdi-alert-circle',
        'mdi-alert-outline',
        'mdi-alert-octagon',
        'mdi-alert-decagram',
        'mdi-airplane',
        'mdi-car',
        'mdi-car-back',
        'mdi-car-estate',
        'mdi-home-group',
        'mdi-ferry',
        'mdi-train',
        'mdi-package-variant-closed',
        'mdi-alert-box-outline',
        'mdi-adjust',
        'mdi-bank',
        'mdi-bank-minus',
        'mdi-bank-plus',
        'mdi-bank-outline',
        'mdi-bank-remove',
        'mdi-bank-transfer',
        'mdi-bank-transfer-in',
        'mdi-bank-transfer-out',
        'mdi-basket',
        'mdi-bell-ring-outline',
        'mdi-calendar',
        'mdi-calendar-account',
        'mdi-calendar-alert',
        'mdi-calendar-blank',
        'mdi-calendar-clock',
        'mdi-calendar-edit',
        'mdi-calendar-export',
        'mdi-calendar-import',
        'mdi-calendar-question',
        'mdi-calendar-outline',
        'mdi-calendar-plus',
        'mdi-calendar-text',
        'mdi-calendar-today',
        'mdi-widgets',
        'mdi-airport',
        'mdi-alarm',
        'mdi-alarm-check',
        'mdi-alarm-bell',
        'mdi-alarm-light',
        'mdi-alarm-light-outline',
        'mdi-alarm-multiple',
        'mdi-alarm-off',
        'mdi-alarm-snooze',
        'mdi-anchor',
        'mdi-android-messages',
        'mdi-antenna',
        'mdi-application',
        'mdi-apps',
        'mdi-application-export',
        'mdi-application-import',
        'mdi-archive',
        'mdi-arrow-decision',
        'mdi-arrow-down',
        'mdi-arrow-down-bold',
        'mdi-arrow-down-bold-box',
        'mdi-arrow-down-bold-box-outline',
        'mdi-arrow-down-bold-circle',
        'mdi-arrow-down-bold-circle-outline',
        'mdi-arrow-down-bold-hexagon-outline',
        'mdi-arrow-down-bold-outline',
        'mdi-asterisk',
        'mdi-at',
        'mdi-attachment',
        'mdi-autorenew',
        'mdi-timer',
        'mdi-barcode',
        'mdi-barcode-off',
        'mdi-barcode-scan',
        'mdi-barn',
        'mdi-barrel',
        'mdi-basket',
        'mdi-basket-outline',
        'mdi-bell',
        'mdi-bell-outline',
        'mdi-bell-off',
        'mdi-bell-off-outline',
        'mdi-bell-circle',
        'mdi-bell-circle-outline',
        'mdi-block-helper',
        'mdi-blur',
        'mdi-book-open-page-variant',
        'mdi-book-open-variant',
        'mdi-book-open',
        'mdi-book-open-outline',
        'mdi-bookmark',
        'mdi-bookmark-check',
        'mdi-bookmark-multiple',
        'mdi-bookmark-multiple-outline',
        'mdi-briefcase',
        'mdi-camcorder',
        'mdi-camera',
        'mdi-camera-iris',
        'mdi-camera-outline',
        'mdi-camera-rear',
        'mdi-camera-wireless',
        'mdi-cctv',
        'mdi-cellphone',
        'mdi-cellphone-android',
        'mdi-cellphone-iphone',
        'mdi-cellphone-wireless',
        'mdi-chart-areaspline',
        'mdi-chart-bar',
        'mdi-chart-bar-stacked',
        'mdi-chart-bell-curve',
        'mdi-chart-bell-curve-cumulative',
        'mdi-chart-bubble',
        'mdi-chart-donut',
        'mdi-chart-donut-variant',
        'mdi-chart-gantt',
        'mdi-chart-histogram',
        'mdi-chart-line',
        'mdi-chart-line-stacked',
        'mdi-chart-line-variant',
        'mdi-chart-pie',
        'mdi-chart-scatter-plot',
        'mdi-chart-timeline-variant',
        'mdi-chat',
        'mdi-chat-alert',
        'mdi-chat-outline',
        'mdi-draw',
        'mdi-cancel',
        'mdi-cart',
        'mdi-cart-arrow-down',
        'mdi-cart-arrow-up',
        'mdi-cart-outline',
        'mdi-cash',
        'mdi-cash-100',
        'mdi-cash-multiple',
        'mdi-cash-register',
        'mdi-cash-usd',
        'mdi-cash-usd-outline',
        'mdi-certificate',
        'mdi-certificate-outline',
        'mdi-chair-rolling',
        'mdi-check-all',
        'mdi-cloud',
        'mdi-cloud-outline',
    ];
}

export {
  icons
};