<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-3">
        <v-row class="justify-space-arround">
            <v-col cols="12" xl="10">
                <v-card class="default" outlined height="100%">
                    <EditorToolbar
                        :headline="selectedItem && selectedItem.name ? selectedItem.name : ''"
                        :isModified="isModified"
                        :isWaiting="isWaiting"
                        :editMode="editMode"
                        @onSaveItem="$emit('onSaveItem')"
                        @onDeleteItem="$emit('onDeleteItem')"
                        @onCreateItem="$emit('onCreateItem')"
                        />
                    <v-card-text>
                        <v-autocomplete
                            v-model="identifierModel"
                            :label="$t('settings.companyGroupHelp.identifier.fieldLabel')"
                            :hint="$t('settings.companyGroupHelp.identifier.fieldHint')"
                            :items="items"
                            item-value="fieldName"
                            item-text="name"
                            :error-messages="errors.collect('identifier')"
                            data-vv-name="identifier"
                            v-validate="'required'"
                            required
                            spellcheck="true"
                            persistent-hint
                            return-object
                            @input="$emit('modified', $event)"
                        />
                        <v-switch
                            v-model="model.showDefaultHelp"
                            color="primary"
                            data-vv-name="showDefaultHelp"
                            :label="$t('settings.companyGroupHelp.showDefaultHelp.fieldLabel')"
                            :hint="$t('settings.companyGroupHelp.showDefaultHelp.fieldHint')"
                            class="mt-6"
                            @change="$emit('modified', $event)"
                        ></v-switch>
                        <v-switch
                            v-model="model.useLayer"
                            color="primary"
                            data-vv-name="useLayer"
                            :label="$t('settings.companyGroupHelp.useLayer.fieldLabel')"
                            :hint="$t('settings.companyGroupHelp.useLayer.fieldHint')"
                            class="mt-0"
                            @change="$emit('modified', $event)"
                        ></v-switch>
                        <v-combobox
                            v-model="model.fieldIcon"
                            :items="iconItems"
                            chips
                            clearable
                            data-vv-name="fieldIcon"
                            :label="$t('settings.companyGroupHelp.fieldIcon.fieldLabel')"
                            @input="$emit('modified', $event)"
                        >
                            <template v-slot:selection="data">
                                <div
                                    :key="data.item"
                                    @click.stop="
                                        data.parent.selectedIndex = data.index
                                    "
                                    @input="data.parent.selectItem(data.item)"
                                >
                                    <v-icon x-large>{{ data.item }}</v-icon>
                                </div>
                            </template>
                            <template v-slot:item="{ item }">
                                <v-list-item-content>
                                    <v-icon x-large>{{ item }}</v-icon>
                                </v-list-item-content>
                            </template>
                        </v-combobox>
                    </v-card-text>
                    <v-card-text class="pa-0">
                        <LeaTranslationTableInput v-model="model.translations" required autofocus @modified="$emit('modified', $event)" fieldValue="description" :fieldText="$t('settings.companyGroupHelp.descriptionField.fieldLabel')" fieldType="editor" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isUUID } from '@/components/vvt/ModelMixin.js';
import EditorToolbar from '../EditorToolbar.vue';
import LeaTranslationTableInput from '@/components/Input/LeaTranslationTableInput';
import { icons } from '@/utils/iconHelper';

export default {
    name: 'SettingsGroupsOfPersonsEditorMask',
    inject: {
        $validator: '$validator',
    },
    components: {
        EditorToolbar,
        LeaTranslationTableInput
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        value: {
            type: Object,
            default: null
        },
        availableLanguages: {
            type: Array,
            default: () => []
        },
        currentLanguage: {
            type: String,
            default: null
        },
        isWaiting: {
            type: Boolean,
            default: false
        },
        isModified: {
            type: Boolean,
            default: false
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    data() {
        return {
            model: this.value,
            disabled: false,
            iconItems: icons(),
        };
    },
    computed: {
        selectedItem() {
            if(!this.identifierModel) {
                return null;
            }
            let selectedItem = this.items.find(x => x.fieldName === this.identifierModel.fieldName && x.entity === this.identifierModel.entity);
            return selectedItem;
        },
        editMode() {
            return !isUUID(this.model.id);
        },
        identifierModel: {
            get () {
                return !this.model || !this.model.fieldName ? null : this.model;
            },
            set (val) {
                this.model.fieldName = val.fieldName
                this.model.entity = val.entity
                this.model.childFieldName = val.childFieldName ? val.childFieldName : null
            }
        }
    },
    mounted() {
        if (isUUID(this.model.id)) {
            this.model.clientId = this.value.id;
        }
    },
};
</script>
