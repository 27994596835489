<template>
    <div>
        <GenericCrud
            route-name="SettingsCompanyGroupHelp"
            route-action="list"
            :navbar-title="$t('settings.companyGroupHelp.buttonLabel')"
            :navbar-back-route-without-history="{ name: 'Settings' }"
            main-fist-scroll-to-selected
            :storeData="{
                mainListItems: {
                    action: 'companyGroupHelp/fetchList',
                    payload: { force: true },
                    options: null,
                    itemText: x => {
                        return this.getFieldnameLabel(x);
                    },
                },
                mainItemById: {
                    action: 'companyGroupHelp/fetchById',
                    payload: null,
                    options: null,
                },
                edit: {
                    action: 'companyGroupHelp/edit',
                    payload: null,
                    options: null,
                    refetch: true,
                    hideButton: true
                },
                add: {
                    action: 'companyGroupHelp/create',
                    payload: null,
                    options: null,
                },
                delete: {
                    action: 'companyGroupHelp/delete',
                    payload: null,
                    options: null,
                    hideButton: true
                }
            }"
            :newItemModelFields="{
                entity: 'App\Entity\ProcessingActivity\ProcessingActivity',
                fieldName: null,
                childFieldName: null,
                description: null,
                showDefaultHelp: false,
                useLayer: false,
                fieldIcon: null,
                locale: currentLanguage,
                translations: defaultTranslations,
            }"
        >
            <template v-slot:editorMask="x">
                <EditorMask
                    v-model="x.selectedMainItem"
                    :items="helpItems"
                    :key="x.id"
                    :available-languages="availableLanguages"
                    :current-language="currentLanguage"
                    :isWaiting="x.isWaiting"
                    :isModified="x.isModified"
                    @modified="x.onSetModified"
                    @onSaveItem="x.onSaveItem"
                    @onDeleteItem="x.onDeleteItem"
                    @onCreateItem="x.onCreateItem"
                />
            </template>
        </GenericCrud>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GenericCrud from '@/components/generic/GenericCrud.vue';
import EditorMask from './EditorMask.vue';
import processingActivityMixin from '@/mixins/processingActivityMixin';

export default {
    name: 'SettingsCompanyGroupHelpIndex',
    components: {
        GenericCrud,
        EditorMask
    },
    mixins: [processingActivityMixin],
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            availableLanguages: 'languageSettings/getAvailableLanguages',
            currentLanguage: 'languageSettings/getCurrentLanguage',
        }),
        defaultTranslations() {
            let translations = this.availableLanguages.map(x => {
                return { title: '', locale: x};
            });
            
            return translations.sort((a, b) => {
                return (b.locale === this.currentLanguage && a.locale !== this.currentLanguage) ? 1 : -1;
            });
        },
        helpItems () {
            return this.processingActivityFields.filter(x => x.enabledForGroupHelp)
        }
    },
    methods: {
        ...mapActions({
            fetchSettings: 'companyGroupSettings/fetch'
        }),
        getFieldnameLabel (helpItem) {
            const item = this.helpItems.find(x => x.entity === helpItem.entity && x.fieldName === helpItem.fieldName && this.$objectPropertyExists(x, 'childFieldName') && x.childFieldName === helpItem.childFieldName);
            return item && item.name ? item.name : 'n/a';
        }
    },
    created () {
        this.fetchSettings();
        this.setLocale(this.$i18n.locale);
    }
};
</script>
