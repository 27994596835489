var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GenericCrud',{attrs:{"route-name":"SettingsCompanyGroupHelp","route-action":"list","navbar-title":_vm.$t('settings.companyGroupHelp.buttonLabel'),"navbar-back-route-without-history":{ name: 'Settings' },"main-fist-scroll-to-selected":"","storeData":{
            mainListItems: {
                action: 'companyGroupHelp/fetchList',
                payload: { force: true },
                options: null,
                itemText: function (x) {
                    return this$1.getFieldnameLabel(x);
                },
            },
            mainItemById: {
                action: 'companyGroupHelp/fetchById',
                payload: null,
                options: null,
            },
            edit: {
                action: 'companyGroupHelp/edit',
                payload: null,
                options: null,
                refetch: true,
                hideButton: true
            },
            add: {
                action: 'companyGroupHelp/create',
                payload: null,
                options: null,
            },
            delete: {
                action: 'companyGroupHelp/delete',
                payload: null,
                options: null,
                hideButton: true
            }
        },"newItemModelFields":{
            entity: 'App\Entity\ProcessingActivity\ProcessingActivity',
            fieldName: null,
            childFieldName: null,
            description: null,
            showDefaultHelp: false,
            useLayer: false,
            fieldIcon: null,
            locale: _vm.currentLanguage,
            translations: _vm.defaultTranslations,
        }},scopedSlots:_vm._u([{key:"editorMask",fn:function(x){return [_c('EditorMask',{key:x.id,attrs:{"items":_vm.helpItems,"available-languages":_vm.availableLanguages,"current-language":_vm.currentLanguage,"isWaiting":x.isWaiting,"isModified":x.isModified},on:{"modified":x.onSetModified,"onSaveItem":x.onSaveItem,"onDeleteItem":x.onDeleteItem,"onCreateItem":x.onCreateItem},model:{value:(x.selectedMainItem),callback:function ($$v) {_vm.$set(x, "selectedMainItem", $$v)},expression:"x.selectedMainItem"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }